<template>
  <div class="header">
    <header
      class="fixed w-100 bg-cl-primary brdr-cl-secondary"
      :class="{ 'is-visible': navVisible }"
    >
      <div class="container mx-mobile">
        <!-- Header on Homepage, Category, Product etc.: Logo and Buttons -->
        <div class="row between-xs middle-xs" v-if="!isCheckoutPage || isThankYouPage">
          <Logo class="col-md-4 col-xs-3 middle-xs" :logos="partnerLogos" :show-dividers="displayPartnerProps" />
          <div class="header-right-side inline-flex right-icons py5 pt5">
            <configurator-icon @click.native="toggleConfigurator" />
            <div class="nav-divider brdr-cl-accent brdr-right-1 px1" />
            <microcart-icon class="microcart-icon pointer" />
            <account-icon class="account-icon pointer" />
          </div>
        </div>

        <!-- Header on Checkout page: Back button, Acount/login Button -->
        <div class="checkout-header-container row between-xs middle-xs px15 py5" v-if="isCheckoutPage && !isThankYouPage">
          <div class="col-xs-3 col-md-3 middle-xs">
            <div>
              <router-link
                :to="localizedRoute({ name: 'home' })"
                class="back-button-wrapper links"
              >
                <div class="back-button">
                  <arrow-icon stroke="#4f4f4f" />
                  <span> {{ $t('Back to rental') }} </span>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-xs-3 col-md-3 end-xs">
            <div>
              <a
                v-if="!currentUser"
                href="#"
                @click.prevent="gotoAccount"
                class="cl-tertiary links"
              >{{ $t('Login to your account') }}</a>
              <span v-else>{{ $t('You are logged in as {firstname}', currentUser) }}</span>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="header-placeholder" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CurrentPage from 'theme/mixins/currentPage';
import AccountIcon from 'theme/components/core/blocks/Header/AccountIcon';
import CompareIcon from 'theme/components/core/blocks/Header/CompareIcon';
import Logo from 'theme/components/core/Logo';
import MicrocartIcon from 'theme/components/core/blocks/Header/MicrocartIcon';
import ConfiguratorIcon from '../../../theme/anyrent/SearchBar/ConfiguratorIcon';
import ArrowIcon from '../ArrowIcon';
import ScrollDirection from '@vue-storefront/core/mixins/ScrollDirection';
import config from 'config';
import sbcrConfig from '@vue-storefront/core/partner-config/config.sbcr.ts';
import highlandSportConfig from '@vue-storefront/core/partner-config/config.highlandsport';

export default {
  name: 'Header',
  components: {
    ArrowIcon,
    ConfiguratorIcon,
    AccountIcon,
    CompareIcon,
    Logo,
    MicrocartIcon
  },
  mixins: [CurrentPage, ScrollDirection],
  data () {
    return {
      navVisible: true
    };
  },
  mounted () {
    this.$on('scroll-direction-change', this.handleScrollDirectionChange);
  },
  computed: {
    ...mapGetters({
      category: 'category-next/getCurrentCategory'
    }),
    ...mapState({
      currentUser: state => state.user.current
    }),
    searchBarVisibilityTreshold () {
      const hasValidSlug =
        this.category?.slug && this.$route.params?.slug &&
        this.category.slug === this.$route.params.slug;

      return hasValidSlug ? 400 : 54;
    },
    isThankYouPage () {
      return this.$store.state.checkout.isThankYouPage
        ? this.$store.state.checkout.isThankYouPage
        : false;
    },
    partnerLogos () {
      switch (this.currentPartner) {
        case sbcrConfig.id:
          return [
            {
              path: '../../assets/any-circle.svg',
              pathResp: '../../assets/any-circle.svg',
              width: 'auto',
              height: '44px'
            },
            {
              path: '../../assets/SBCR_circle.svg',
              pathResp: '../../assets/SBCR_logo_header_plain.svg',
              width: 'auto',
              height: '50px'
            }
          ];
        case highlandSportConfig.id:
          return [
            {
              path: '../../assets/any-circle.svg',
              pathResp: '../../assets/any-circle.svg',
              width: 'auto',
              height: '44px'
            },
            {
              path: '../../assets/highlandsport-logo.svg',
              pathResp: '../../assets/highlandsport-logo.svg',
              width: 'auto',
              height: '50px'
            }
          ];
        default:
          return [{
            path: '../../assets/anylogo.svg',
            pathResp: '../../assets/anylogo.svg',
            width: 'auto',
            height: '66px'
          }];
      }
    },
    currentPartner () {
      return this.$ssrContext?.partnerData.id || config.partnerData.id;
    },
    displayPartnerProps () {
      return this.$ssrContext?.partnerData.displayPartnerPages || config.partnerData.displayPartnerPages;
    }
  },
  methods: {
    handleScrollDirectionChange (event) {
      const dir = event.direction;
      const scrollY = event.scrollY;
      if (scrollY > this.searchBarVisibilityTreshold) {
        if (dir === 'down') {
          this.navVisible = false;
        } else {
          this.navVisible = true;
        }
        // To show the header (reset it's posisiton) initially when changing routes
      } else {
        this.navVisible = true;
      }
    },
    toggleConfigurator () {
      this.$store.dispatch('ui/toggleConfigurator');
    },
    gotoAccount () {
      this.$bus.$emit('modal-toggle', 'modal-signup');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$color-icon-hover: color(secondary, $colors-background);
$color-partner: color(partner);

.checkout-header-container {
  height: 60px;
}

.header-right-side {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

.nav-divider {
  border-right: 1px solid color(accent, $colors-theme);
  align-self: stretch;
}

header {
  height: 84px;
  top: -85px;
  z-index: 3;
  transition: top 0.2s ease-in-out;

  &.is-visible {
    top: 0;
  }
}

.hover-underline:hover {
  text-decoration: underline;
}

.icon {
  opacity: 0.6;

  &:hover,
  &:focus {

    opacity: 1;
  }
}

.pt1 {
  padding-top: 1px;
}

.ml40 {
  margin-left: 40px;
}

.mt2 {
  margin-top: 2px;
}

.w50 {
  width: 50px;
}

.w24 {
  width: 24px !important;
}

.right-icons {
  //for edge
  float: right;
}

.header-placeholder {
  height: 54px;
}

.links {
  text-decoration: underline;
}

.social-link:hover:after {
  display: none;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 15px;
}

.back-button-wrapper:after {
  display: none;
}

.align-items-start {
  align-items: start;
}

.brdr-cl-accent {
  border-color: $color-partner;
}

@media (max-width: 767px) {
  .header-right-side {
    gap: 0.5rem
  }

  .mt10-md {
    margin-top: 10px;
  }
  .row.middle-xs {
    margin: 0 -15px;

    &.py5 {
      margin: 0;
    }
  }
  .col-xs-2:first-of-type {
    padding-left: 0;
  }
  .col-xs-2:last-of-type {
    padding-right: 0;
  }
  a,
  span {
    font-size: 12px;
  }
}

.align-items-center {
  align-items: center;
}

@media (max-width: 575px) {
  .mx-mobile {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (max-width: 350px) {
  .header-right-side {
    gap: 0.2rem;
  }
}
</style>
